.content {
  background-color: #C0CEE6;
  min-height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(14px + 0.5vmin);
  color: black;
  padding-top: 65px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.login {
  background-color: #C0CEE6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}